// Log
import log from "loglevel";

import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
// components
import { useSettingsContext } from 'src/components/settings';
// config
import { allLangs, defaultLang } from './config-lang';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const settings = useSettingsContext();

  // const getBrowserLanguage = () => {
  //   const browserLang = navigator.language || navigator.languages[0];
  //   return browserLang.split('-')[0]; // Extract language code
  // };

  // Get the stored language from localStorage or fallback to browser language
  // const langStorage =  getBrowserLanguage();
  const langStorage = "en";
  log.info("langStorage", langStorage);

  // Find the current language or fall back to default
  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  // Change the language and update the settings context
  const onChangeLang = useCallback(
    (newLang) => {
      i18n.changeLanguage(newLang);
      settings.onChangeDirectionByLang(newLang);
      localStorage.setItem('i18nextLng', newLang); // Ensure language is stored in localStorage
    },
    [i18n, settings]
  );

  // Update language on mount if browser language is different from stored language
  useEffect(() => {
    if (!langStorage || langStorage !== i18n.language) {
      onChangeLang(langStorage);
    }
  }, [langStorage, i18n.language, onChangeLang]);

  return {
    allLangs,
    t,
    currentLang,
    onChangeLang,
  };
}
