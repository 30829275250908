const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SHOWROOM: '/showroom',
  USER: '/user',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',

  
  // AUTH
  auth: {

    firebase: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    proSubscription: `${ROOTS.DASHBOARD}/pricing`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      userPage: `${ROOTS.USER}/userPage`,
      details:(id) =>  `${ROOTS.USER}/postDetails/${id}`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: (id) => `${ROOTS.DASHBOARD}/user/userprofile/${id}`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    sampleroom: {
      root: `${ROOTS.DASHBOARD}/sampleroom`,
      new: `${ROOTS.DASHBOARD}/sampleroom/new`,
      details: (id) => `${ROOTS.DASHBOARD}/sampleroom/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/sampleroom/${id}/edit`,
      sharedWithMe: `${ROOTS.DASHBOARD}/sampleroom/sharedWithMe`,
      sharedWithMeDetails: (id) => `${ROOTS.DASHBOARD}/sampleroom/sharedWithMe/${id}`,
    },
    recents: {
      root: `${ROOTS.DASHBOARD}/recents`,
      new: `${ROOTS.DASHBOARD}/recents/new`,
      details: (id) => `${ROOTS.DASHBOARD}/recents/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/recents/${id}/edit`,
    },
    feed: {
      root: `${ROOTS.DASHBOARD}/feed`,
      new: `${ROOTS.DASHBOARD}/feed/new`,
      details: (id) => `${ROOTS.DASHBOARD}/feed/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/feed/${id}/edit`,
    },
    products: {
      root: `${ROOTS.DASHBOARD}/products`,
      details: (id) => `${ROOTS.DASHBOARD}/products/${id}`,
    },
    library: {
      root: `${ROOTS.DASHBOARD}/library`,
      new: `${ROOTS.DASHBOARD}/library/new`,
      details: (id) => `${ROOTS.DASHBOARD}/library/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/library/${id}/edit`
    },
  },

  showroom: {
    collection: {
      root: `${ROOTS.SHOWROOM}/collection`,
      new: `${ROOTS.SHOWROOM}/collection/new`,
      details: (id) => `${ROOTS.SHOWROOM}/collection/${id}`,
      edit: (id) => `${ROOTS.SHOWROOM}/collection/${id}/edit`,
    },
    showcase: {
      root: `${ROOTS.SHOWROOM}/showcase`,
      new: `${ROOTS.SHOWROOM}/showcase/new`,
      details: (id) => `${ROOTS.SHOWROOM}/showcase/${id}`,
      edit: (id) => `${ROOTS.SHOWROOM}/showcase/${id}/edit`,
    }
  },

};
