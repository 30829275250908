import { lazy, Suspense } from 'react';


// ----------------------------------------------------------------------



const Ar = lazy(() => import('src/pages/ar/ar'));



export const arRoutes = [
    {
        path: 'ar',
        children: [
          { path: ':companyId/:productId/:productName', element: <Ar /> },
        ],
      }
      
];
