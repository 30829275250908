import { enUS, frFR, zhCN, viVN, arSA, trTR, esES, ptBR, ruRU, deDE, jaJP, koKR, itIT, nlNL, elGR, urPK, faIR, plPL, roRO, csCZ, huHU, svSE, daDK, fiFI, thTH, idID, heIL, ukUA, srRS, hrHR, skSK, bgBG, etEE } from '@mui/material/locale';

export const allLangs = [
  { label: 'English', value: 'en', systemValue: enUS, icon: 'flagpack:gb-nir', viewInYourRoom: 'View in Your Room' },
  { label: 'French', value: 'fr', systemValue: frFR, icon: 'flagpack:fr', viewInYourRoom: 'Voir dans votre chambre' },
  { label: 'Vietnamese', value: 'vi', systemValue: viVN, icon: 'flagpack:vn', viewInYourRoom: 'Xem trong phòng của bạn' },
  { label: 'Chinese', value: 'zh', systemValue: zhCN, icon: 'flagpack:cn', viewInYourRoom: '在您的房间查看' },
  { label: 'Arabic', value: 'ar', systemValue: arSA, icon: 'flagpack:sa', viewInYourRoom: 'عرض في غرفتك' },
  { label: 'Turkish', value: 'tr', systemValue: trTR, icon: 'flagpack:tr', viewInYourRoom: 'Odanızda Görüntüleyin' },
  { label: 'Spanish', value: 'es', systemValue: esES, icon: 'flagpack:es', viewInYourRoom: 'Ver en tu habitación' },
  { label: 'Portuguese', value: 'pt', systemValue: ptBR, icon: 'flagpack:pt', viewInYourRoom: 'Ver no seu quarto' },
  { label: 'Russian', value: 'ru', systemValue: ruRU, icon: 'flagpack:ru', viewInYourRoom: 'Просмотр в вашей комнате' },
  { label: 'German', value: 'de', systemValue: deDE, icon: 'flagpack:de', viewInYourRoom: 'Im Zimmer ansehen' },
  { label: 'Japanese', value: 'ja', systemValue: jaJP, icon: 'flagpack:jp', viewInYourRoom: '部屋で見る' },
  { label: 'Korean', value: 'ko', systemValue: koKR, icon: 'flagpack:kr', viewInYourRoom: '방에서 보기' },
  { label: 'Italian', value: 'it', systemValue: itIT, icon: 'flagpack:it', viewInYourRoom: 'Visualizza nella tua stanza' },
  { label: 'Dutch', value: 'nl', systemValue: nlNL, icon: 'flagpack:nl', viewInYourRoom: 'Bekijk in uw kamer' },
  { label: 'Greek', value: 'el', systemValue: elGR, icon: 'flagpack:gr', viewInYourRoom: 'Δείτε στο δωμάτιό σας' },
  { label: 'Urdu', value: 'ur', systemValue: urPK, icon: 'flagpack:pk', viewInYourRoom: 'اپنے کمرے میں دیکھیں' },
  { label: 'Persian', value: 'fa', systemValue: faIR, icon: 'flagpack:ir', viewInYourRoom: 'در اتاق خود مشاهده کنید' },
  { label: 'Polish', value: 'pl', systemValue: plPL, icon: 'flagpack:pl', viewInYourRoom: 'Zobacz w swoim pokoju' },
  { label: 'Romanian', value: 'ro', systemValue: roRO, icon: 'flagpack:ro', viewInYourRoom: 'Vedeți în camera dvs' },
  { label: 'Czech', value: 'cs', systemValue: csCZ, icon: 'flagpack:cz', viewInYourRoom: 'Zobrazit v místnosti' },
  { label: 'Hungarian', value: 'hu', systemValue: huHU, icon: 'flagpack:hu', viewInYourRoom: 'Nézze meg a szobájában' },
  { label: 'Swedish', value: 'sv', systemValue: svSE, icon: 'flagpack:se', viewInYourRoom: 'Se i ditt rum' },
  { label: 'Danish', value: 'da', systemValue: daDK, icon: 'flagpack:dk', viewInYourRoom: 'Se i dit værelse' },
  { label: 'Finnish', value: 'fi', systemValue: fiFI, icon: 'flagpack:fi', viewInYourRoom: 'Katso huoneessasi' },
  { label: 'Thai', value: 'th', systemValue: thTH, icon: 'flagpack:th', viewInYourRoom: 'ดูในห้องของคุณ' },
  { label: 'Indonesian', value: 'id', systemValue: idID, icon: 'flagpack:id', viewInYourRoom: 'Lihat di Kamar Anda' },
  { label: 'Hebrew', value: 'he', systemValue: heIL, icon: 'flagpack:il', viewInYourRoom: 'צפה בחדר שלך' },
  { label: 'Ukrainian', value: 'uk', systemValue: ukUA, icon: 'flagpack:ua', viewInYourRoom: 'Переглянути у вашій кімнаті' },
  { label: 'Serbian', value: 'sr', systemValue: srRS, icon: 'flagpack:rs', viewInYourRoom: 'Pogledajte u svojoj sobi' },
  { label: 'Croatian', value: 'hr', systemValue: hrHR, icon: 'flagpack:hr', viewInYourRoom: 'Pogledajte u svojoj sobi' },
  { label: 'Slovak', value: 'sk', systemValue: skSK, icon: 'flagpack:sk', viewInYourRoom: 'Zobraziť vo vašej izbe' },
  { label: 'Bulgarian', value: 'bg', systemValue: bgBG, icon: 'flagpack:bg', viewInYourRoom: 'Вижте в стаята си' },
  { label: 'Estonian', value: 'et', systemValue: etEE, icon: 'flagpack:ee', viewInYourRoom: 'Vaata oma toas' }
];

export const defaultLang = allLangs[0]; // English
