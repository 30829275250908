import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
// Log
import log from "loglevel";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { UseGetSubscription } from 'src/api/product';

// config
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);
            const docSnap = await getDoc(userProfile);
            const profile = docSnap.data();

            if (!profile.verified) {
              await updateDoc(userProfile, {
                verified: true,
              });

              profile.verified=true;
            }

            if (profile.imageURL) {
              log.info("profile", profile);
              const gsURL = profile.imageURL;
              const gsURLArray = gsURL.split("com/");
              const filePath = gsURLArray[1];
              const storage = getStorage();
              const modelRef = ref(storage, filePath);

              const userPhotoURL = await getDownloadURL(modelRef);
              log.info("userPhotoURL: ", userPhotoURL);
              profile.photoURL = userPhotoURL;
            }

            const sub = await UseGetSubscription();
            log.info("sub", sub);

            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...user,
                  ...profile,
                  subscription: sub,
                  id: user.uid
                },
              },
            });
          } else {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });


    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
    
    const analytics = getAnalytics();
    logEvent(analytics, "login", {
      userEmail: email,
    });
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {

    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    const actionCodeSettings = {
      url: 'https://webapp.teledomica.com/', // Replace with your app URL where users will be redirected after verifying their email
      // url: 'https://ext-test-teledomica.web.app/', // Replace with your app URL where users will be redirected after verifying their email
      handleCodeInApp: true,
    };
    await sendEmailVerification(newUser.user, actionCodeSettings);

    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);
    // console.log("register", newUser.user?.uid)
    await setDoc(userProfile, {
      userId: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      verified: false,
      createdAt: new Date().toLocaleDateString("en-US"),
    });

    const analytics = getAnalytics();
    logEvent(analytics, "registered", {
        userId:newUser.user?.uid,
      });
    
    await signOut(AUTH);

  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
